<template>
  <div class="">
    <b-card title="Google Map Configuration" tag="article" class="mb-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group
            id="input-group-1"
            label="Map Key: "
            label-for="input-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Map Key"
              rules="required"
            >
              <b-form-input
                id="input-1"
                v-model="form.map_key"
                type="text"
                placeholder="Enter Map Key"
                class="data-text"
                required
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Map Radius: "
            label-for="input-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Map Radius"
              rules="required"
            >
              <b-form-input
                id="input-1"
                v-model="form.attendance_radius"
                type="text"
                placeholder="Enter Map Radius"
                class="data-text"
                required
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Map Zoom Count: "
            label-for="input-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Map Zoom Count"
              rules="required"
            >
              <b-form-input
                id="input-1"
                v-model="form.zoom"
                type="text"
                placeholder="Enter Map Zoom Count"
                class="data-text"
                required
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            v-if="$can('list', 'Industry')"
            @click.prevent="updateMapConfig"
            type="submit"
            variant="success"
            >Submit</b-button
          >
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BButton, BCard } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@validations";
import { toast } from "@/utils/toast";
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form: {
        map_key: "",
        attendance_radius: "",
        zoom: "",
      },
      email,
      required,
    };
  },
  mounted() {
    this.getMapConfig();
  },
  methods: {
    getMapConfig() {
      this.form = this.$store.state.mapConfig.mapConfig;
    },
    updateMapConfig() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("mapConfig/updateMapConfig", this.form)
            .then((res) => {
              toast(
                "Success",
                "CheckCircleIcon",
                "success",
                "Map Config Updated Successfully"
              );
            })
            .catch((err) => {
              toast(
                "Error",
                "AlertTriangleIcon",
                "danger",
                "Something went wrong"
              );
            });
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
  },
};
</script>

<style></style>
