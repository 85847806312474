<template>
  <div>
    <b-card title="Weekly Weekend Configuration" tag="article" class="mb-2">
      <div class="row">
        <div
          v-if="loggedInUser.roles[0].name === 'superadmin'"
          class="col-md-6"
        >
          <label for="" class="label-font">Subsidiary Company</label>
          <b-form-group>
            <v-select
              v-model="query.company"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="companies"
              label="name"
              placeholder="Select Subsidiary Company"
              @input="getCustomers"
            />
          </b-form-group>
        </div>
        <div
          v-if="
            loggedInUser.roles[0].name === 'superadmin' ||
            loggedInUser.roles[0].name === 'admin'
          "
          class="col-md-6"
        >
          <label for="" class="label-font">Client</label>
          <b-form-group>
            <v-select
              v-model="query.customer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customers"
              label="name"
              placeholder="Select Client"
            />
          </b-form-group>
        </div>
      </div>
      <b-form-group label="Select Weekend Days" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="days"
          :options="weekDays"
          :aria-describedby="ariaDescribedby"
          value-field="id"
          text-field="name"
        ></b-form-checkbox-group>
      </b-form-group>
      <b-button
        v-if="$can('list', 'Industry')"
        @click.prevent="updateWeekendDays"
        type="submit"
        variant="info"
        >Submit</b-button
      >
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import {
  BFormCheckbox,
  BButton,
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckboxGroup,
} from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    vSelect,
  },
  data() {
    return {
      loggedInUser: JSON.parse(getLogedInUser()),
      status: 0,
      weekDays: [],
      days: [],
      companies: [],
      customers: [],
      query: {
        company: "",
        customer: "",
      },
    };
  },
  mounted() {
    this.getDay();
    this.getCompanies();
    if (this.loggedInUser.roles[0].name === "customer") {
      this.query.customer = this.loggedInUser;
    }
    this.getWeekendDays();
  },
  methods: {
    getWeekendDays() {
      let query = {};
      if (this.loggedInUser.roles[0].name === "customer") {
        query.user_id = this.loggedInUser.id;
      } else {
        if (this.query.customer && this.query.customer.id) {
          query.user_id = this.query.customer.id;
        }
      }
      if (query.user_id) {
        this.$store
          .dispatch("globalConfig/getWeekendDays", query)
          .then((res) => {
            if (res.data.status_code == 200) {
              this.days = res.data.data;
            }
          });
      }
    },
    getCompanies() {
      if (this.loggedInUser.roles[0].name === "admin") {
        this.query.company = this.loggedInUser;
        this.getCustomers();
      } else {
        this.$store
          .dispatch("company/getCompaniesForDropdown")
          .then((response) => {
            this.companies = response.data;
          });
      }
    },
    async getCustomers() {
      if (this.query.company && this.query.company.id) {
        await this.$store
          .dispatch("customer/getCustomersForDropdown", {
            user_id: this.query.company.id,
          })
          .then((response) => {
            this.customers = response.data;
          });
      }
    },
    getDay() {
      this.$store.dispatch("employee/getDay").then((res) => {
        if (res.data.status_code == 200) {
          this.weekDays = res.data.data;
        }
      });
    },
    updateWeekendDays() {
      let data = {
        days: this.days,
      };
      if (this.query.customer && this.query.customer.id) {
        data.user_id = this.query.customer.id;
      } else if (this.query.company && this.query.company.id) {
        data.user_id = this.query.company.id;
      } else {
        data.user_id = this.loggedInUser.id;
      }
      this.$store
        .dispatch("globalConfig/updateWeekendDays", data)
        .then((res) => {
          if (res.status) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Weekend Days Updated Successfully!"
            );
          }
        })
        .catch((err) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            err.response?.data?.message || "Error Updating Weekend Days!"
          );
        });
    },
  },
};
</script>

<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
