<template>
  <div>
    <b-card-group columns>
      <div class="child-col" v-if="$can('list', 'Industry')">
        <xero-config></xero-config>
      </div>
      <div v-if="$can('list', 'Industry')">
        <weekly-weekend-config></weekly-weekend-config>
      </div>
      <div v-if="$can('list', 'Industry')">
        <map-config></map-config>
      </div>
      
      <!-- <div v-if="$can('list', 'Industry')">
        <env-config></env-config>
      </div> -->
      
    </b-card-group>
  </div>
</template>

<script>
import XeroConfig from "./xero-config/index.vue";
import MapConfig from "./map-config/index.vue";
import EnvConfig from "./env-config/index.vue";
import WeeklyWeekendConfig from "./weekly-weekend-config/index.vue";
import { BCardGroup } from "bootstrap-vue";
export default {
  components: {
    XeroConfig,
    MapConfig,
    EnvConfig,
    WeeklyWeekendConfig,
    BCardGroup,
  },
};
</script>

<style scoped>

.card-columns {
    -webkit-column-count: 2 !important;
    -moz-column-count: 2 !important;
    column-count: 2 !important;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
}
</style>
