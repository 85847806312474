<template>
  <div>
    <b-card title="Xero Configuration Status" tag="article" class="mb-2">
      <div>
        <b-form-checkbox
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          value="1"
          unchecked-value="0"
          class="mb-1"
          disabled
        >
          Xero is
          <span v-if="status == '1'">connected and ready to use for</span>
          <span v-else>not connected with</span> your system.
        </b-form-checkbox>
        <!-- if status is not accepted then add a button for xero authentication -->
        <b-button
          v-if="status == '0' && $can('list', 'Industry')"
          variant="primary"
          class="text-center"
        >
          <a
            :href="
              this.$store.state.appConfig.imageBaseUrl + 'success-callback'
            "
            class="text-white"
            target="_blank"
          >
            Connect To Xero
          </a>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BButton,
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      status: 0,
    };
  },
  mounted() {
    this.getXeroServiceStatus();
  },
  methods: {
    getXeroServiceStatus() {
      this.$store
        .dispatch("globalConfig/getXeroServiceStatus")
        .then((res) => {
          if (res.status_code == 200) {
            this.status = res.data.status;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
