<template>
  <b-card title="Environment Configuration" tag="article" class="mb-2">
    <b-form>
      <div v-for="(key, index) in keys" :key="index">
        <b-form-group
          :id="'input-group-' + index"
          :label="key.key"
          :label-for="'input-' + index"
        >
          <b-form-input
            :id="'input-' + index"
            v-model="key.value"
            :placeholder="`Please Enter ${key.key}`"
            class="data-text"
            required
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button
        v-if="$can('list', 'Industry')"
        @click.prevent="updateXeroEnv"
        >Update</b-button
      >
    </b-form>
  </b-card>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BButton, BCard } from "bootstrap-vue";
import { toast } from "@/utils/toast";
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
  },
  data() {
    return {
      keys: [],
    };
  },
  mounted() {
    this.getXeroEnv();
  },
  methods: {
    getXeroEnv() {
      this.$store
        .dispatch("globalConfig/getXeroEnv")
        .then((res) => {
          if (res.data.status_code == 200) {
            this.keys = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateXeroEnv() {
      this.$store
        .dispatch("globalConfig/updateXeroEnv", this.keys)
        .then((res) => {
          if (res.status) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Xero Configuration Updated Successfully"
            );
          }
        })
        .catch((err) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            err.response?.data?.message || "Error Syncing With Xero"
          );
        });
    },
  },
};
</script>

<style></style>
